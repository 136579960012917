import { Bindings } from "booking_app/types";
import { JalHeaderNavigationController } from "./jal-header-navigation.controller";

export class JalHeaderNavigationComponent {
  bindings: any = {
    navBarText: Bindings.ONE_WAY,
    navAction: Bindings.EXPRESSION,
  };
  controller: any = JalHeaderNavigationController;
  template: string = `
    <div class="top-menu-container">
      <div class="menu-container">

        <div
          class="dropdown nav locale-dropdown"
          auto-close="always"
        >
          <a class="dropdown-toggle" data-toggle="dropdown" role="button">
            <div class="dropdown-toggle-value">
              <div class="flag dropdown-flag flag-{{ $ctrl.$rootScope.selectedLocale.flag_code }}"></div>
            </div>
            <div class="arrow-down"></div>
          </a>

          <ul class="dropdown-menu regions" role="menu">
            <div class="arrow-box"></div>
            <li class="dropdown-option" role="presentation" ng-repeat="locale in $ctrl.$rootScope.enabledLocales">
              <a
                role="menuitem"
                class="dropdown-item"
                title="{{ ::locale.text }}"
                ng-click="$ctrl.$rootScope.globalState.changeLocale(locale.lang_code)"
              >
                <div class="dropdown-item-name" ng-bind="locale.text"></div>
                <div
                  class="dropdown-item-label flag dropdown-flag flag-{{ ::locale.flag_code }}"
                ></div>
              </a>
            </li>
          </ul>
        </div>

        <div
          class="currency-dropdown dropdown nav currency-dropdown"
          auto-close="always"
        >
          <a class="dropdown-toggle" data-toggle="dropdown" role="button">
            <div class="dropdown-toggle-value">
              <span class="currency-symbol hidden-xs" ng-bind="$ctrl.$rootScope.selectedCurrency.symbol"></span>
              <span class="dropdown-toggle-label" ng-bind="$ctrl.$rootScope.selectedCurrency.code"></span>
            </div>
            <div class="arrow-down"></div>
          </a>

          <ul class="dropdown-menu regions" role="menu">
            <div class="arrow-box"></div>
            <li class="dropdown-option" role="presentation" ng-repeat="currency in $ctrl.$rootScope.allCurrencies">
              <a
                role="menuitem"
                class="dropdown-item"
                ng-click="$ctrl.$rootScope.globalState.changeCurrency(currency.code)"
              >
                <div class="dropdown-item-label" ng-bind="::currency.symbol"></div>
                <div class="dropdown-item-name" ng-bind="::currency.code"></div>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <a class="my-bookings-container link"
        ng-show="$ctrl.isUserLoggedIn()"
        href="/account/booking" translate="wl.my_bookings">
      </a>

      <div class="view-miles-container" ng-click="$ctrl.login()" ng-show="!$ctrl.isUserLoggedIn()">
        <a translate='txt.view_miles_and_booking'></a>
      </div>
      <div class="log-out-container" ng-click="$ctrl.logout()" ng-show="$ctrl.isUserLoggedIn()">
        <i class="fa fa-sign-out"></i>
        <a translate='txt.log_out'>
        </a>
      </div>
    </div>
    <div class="bottom-menu-container" ng-show="!$ctrl.isUserLoggedIn()">
      <div class="login-text">
        <a ng-click="!$ctrl.login()" translate='wl.log_in'></a>
        <span translate="txt.show_your_miles_balance"></span>
      </div>
    </div>
    <div class="bottom-menu-container" ng-show="$ctrl.isUserLoggedIn()">
      <div class="membership-no" translate='wl.jal.jmb_membership_no'
        translate-values='{membership_no: $ctrl.membershipNo()}'>
      </div>
      <div class="miles-details">
        <div class="personal-miles" translate='wl.jal.personal_miles'
          translate-values='{ personal_miles: $ctrl.personalMiles().toLocaleString() }'>
        </div>
        <div ng-if="$ctrl.shouldRenderFamilyMiles()" class="family-miles" translate='wl.jal.family_miles'
          translate-values='{ family_miles: $ctrl.familyMiles().toLocaleString() }'>
        </div>
      </div>
    </div>
    <div class="mobile-button-container">
      <div class="mobile-button" ng-click="$ctrl.toggleSideNav()">
        <div class="translation-box">
          <img cdn-path="/jal/icons/language-icon.png">
        </div>
        <a class="toggle-nav-container">
          <img class="user-icon" cdn-path="/jal/icons/account-icon.png">
          <div class="chevron-down"></div>
        </a>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("jalHeaderNavigation", new JalHeaderNavigationComponent());
