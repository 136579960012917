import { HotelsSearchService } from "booking_app/services/hotels";
import { TravelType } from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";

export class JalProductTypeSwitchController {
  static $inject = [
    "$rootScope",
    "$window",
    "$translate",
    "AppSettings",
    "CarsState",
    "SearchUrlService",
    "GlobalStateService",
  ];

  // bindings

  isMobile: boolean;

  constructor(
      private $rootScope: any,
      private $window: any,
      private $translate: any,
      private AppSettings: any,
      private carsState: any,
      private searchUrlService: any,
      private globalStateService: GlobalStateService,
  ) {
  }

  productText(): string {
    const productKey = this.isMobile ?
      `wl.switch_to_${this.switchToProductText()}_miles` :
      `wl.click_to_switch_to_${this.switchToProductText()}_miles`;

    return this.$translate.instant(productKey);
  }

  triggerSearch(): void {
    const productType = this.isEarn() ? "redeem" : "earn";
    this.switchProductType(productType);

    const searchUrl = this.buildSearchUrl();
    this.$rootScope.isLoading = true;
    this.$window.location.href = searchUrl;
  }

  private buildSearchUrl(): string {
    if (this.globalStateService.isTravelType(TravelType.CARS)) {
      const params = this.searchUrlService.generateCarsParams(this.carsState);
      return this.searchUrlService.createCarsSearchUrl(params);
    } else {
      return this.searchUrlService.createBackToSearchUrl(this.$rootScope);
    }
  }

  private productType(): string {
    return this.$rootScope.globalState.productType;
  }

  private isEarn(): boolean {
    return this.productType() === "earn";
  }

  private switchToProductText(): string {
    return this.isEarn() ? "redeem" : "earn";
  }

  private switchProductType(productType): void {
    this.$rootScope.globalState.productType = productType;
    this.$rootScope.landingPage.url = this.AppSettings.landingPageUrl[productType];
  }
}
