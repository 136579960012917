require('../cars-setup');

global.wlAngularDeps = [
  "ngAnimate",
];

global.loadAppDeps = function () {
  loadCarsAppDeps();
  require('angular-animate');
  require('../../assets/javascripts/whitelabel/jal/locales');
  require('../../assets/javascripts/whitelabel/jal/controllers/jal-controller');
  require('../../assets/javascripts/whitelabel/jal/services');
  require('../../assets/javascripts/whitelabel/jal/components');
}
require('../application');
