import { LandingPageRecommendedDestinationsController } from "./landing-page-recommended-destinations.controller";
import { Bindings } from "booking_app/types";

// we can always change this component to receive data from binding, however I see no point in it right now,
// especially considering the controller on top of this is still the old SearchCtrl
export class LandingPageRecommendedDestinationsComponent {
  bindings: any = {
    onHotelSearch: Bindings.EXPRESSION,
  };
  controller: any = LandingPageRecommendedDestinationsController;
  template: string = `
    <div class="component-title">
      <span translate="wl.recommended_destinations.component_title" />
    </div>

    <div class="scrollable-container">
      <div class="tiles-container">

        <div class="popular-tile" ng-click="$ctrl.search($ctrl.popularTile)">
          <div class="image-container" ng-style="::$ctrl.backgroundImgStyle($ctrl.popularTile)">
            <div class="text-container">
              <div class="most-popular-label" translate="wl.recommended_destinations.most_popular_label"></div>
              <div class="destination-name" translate="{{ $ctrl.popularTile.search_params.name }}"></div>
              <div class="destination-description" translate="{{ $ctrl.popularTile.description }}"></div>
            </div>
            <div class="tile-action-label">
              <i class="fa fa-caret-right"></i>
              <span translate="wl.lp_tiles.action_hotels"></span>
            </div>
          </div>
        </div>

        <div class="normal-tiles">
          <div class="normal-tile" ng-repeat="tile in $ctrl.normalTiles" ng-click="$ctrl.search(tile)">
            <div class="image-container" ng-style="::$ctrl.backgroundImgStyle(tile)">
              <div class="text-container">
                <div class="destination-name" translate="{{ tile.search_params.name }}"></div>
              </div>
              <div class="tile-action-label">
                <i class="fa fa-caret-right"></i>
                <span translate="wl.lp_tiles.action_hotels"></span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  `;
}

angular.module("BookingApp").component(
  "landingPageRecommendedDestinations",
  new LandingPageRecommendedDestinationsComponent());
