import { LoginService } from "booking_app/services/login";

export class JalHeaderNavigationController {
  static $inject = ["$rootScope", "$window", "LoginService"];

  constructor(
    private $rootScope: any,
    private $window: any,
    private loginService: LoginService,
  ) {
  }

  personalMiles(): number {
    if (this.$rootScope.userDetails.user) {
      return this.$rootScope.userDetails.user.redemption_points_balance || 0;
    }
  }

  familyMiles(): number {
    if (this.$rootScope.userDetails.user) {
      return this.$rootScope.userDetails.user.family_miles || 0;
    }
  }

  shouldRenderFamilyMiles(): boolean {
    return Number(this.personalMiles()) !== Number(this.familyMiles());
  }

  membershipNo(): string {
    if (this.$rootScope.userDetails.user) {
      return this.$rootScope.userDetails.user.redemption_member_no;
    }
  }

  logout(): void {
    this.$window.location.assign("/whitelabel/jal/logout");
  }

  login(): void {
    this.$window.location.assign("/whitelabel/jal/login");
  }

  isUserLoggedIn(): boolean {
    return this.$rootScope.userDetails.user !== null;
  }

  toggleSideNav(): void {
    this.$rootScope.globalState.showNavMenu = !this.$rootScope.globalState.showNavMenu;
  }
}
