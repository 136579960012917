import { JalNavigationBarController } from "./jal-navigation-bar.controller";

export class JalNavigationBar {
  controller: any = JalNavigationBarController;
  template: string = `
    <div class="navigation-container" ng-class="{ 'hidden': $ctrl.hideNavBar() }">
      <div class="max-size navigation-buttons">
        <a ng-click="$ctrl.globalStateService.changeTravelTypeAndUrl('hotels')"
          ng-class="{
            active: $ctrl.globalStateService.isTravelType('hotels'),
            has_hover: $ctrl.displayHover()
          }">
          <div class="header-logo hotels"></div>
          <div translate='wl.jal.jmb_hotel_booking'></div>
        </a>
        <a ng-click="$ctrl.globalStateService.changeTravelTypeAndUrl('cars')"
          ng-class="{
            active: $ctrl.globalStateService.isTravelType('cars'),
            has_hover: $ctrl.displayHover()
          }">
          <div class="header-logo cars"></div>
          <div translate='wl.jal.jmb_car_rental'></div>
        </a>
        <a ng-href="{{ $ctrl.KaligoConfig.JAL_SHOP_REDEEM_URL }}"
          ng-class="{ has_hover: $ctrl.displayHover() }">
          <div class="header-logo shop-redeem"></div>
          <div translate='wl.jal.jmb_reward_store'></div>
        </a>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("jalNavigationBar", new JalNavigationBar());
