import { CityTile } from "booking_app/models";
import { UrlUtils } from "booking_app/utils";
import {
  LocationType,
  PopularDestination,
  ProductType,
  TravelType,
 } from "booking_app/types";

type JalProductType  =  ProductType.EARN | ProductType.REDEEM;

export class DestinationsList {
  static $inject = [
    "UrlUtils",
  ];

  jalProductTypes: ProductType[] =  [ProductType.EARN, ProductType.REDEEM];

  landingPageTiles: {
    [travelType in TravelType]: {
      [productType in JalProductType]: CityTile[];
    }
  };
  recommendedDestinations: {
    popularTile: CityTile,
    normalTiles: CityTile[],
  };

  popularDestinations: {
    [locationType in LocationType]: PopularDestination[];
  };

  constructor(
    private urlUtils: UrlUtils,
  ) {
    this.setupRecommendedDestinations();
    this.setupPopularDestinations();
  }

  private setupRecommendedDestinations(): void {
    const imageUrlPrefix = this.urlUtils.imageUrl("");

    this.recommendedDestinations = {
      popularTile: {
        image_url: `${imageUrlPrefix}/jal/homepage/Recommended-Kyoto.jpg`,
        amount: 0,
        name: "recommended-kyoto",
        search_params: {
          id: "cdNS",
          name: "wl.jal.destinations.kyoto",
          lat: 35.0080652565,
          lng: 135.745697021,
        },
        description: "wl.jal.destinations_description.kyoto",
      },
      normalTiles: [{
        image_url: `${imageUrlPrefix}/jal/homepage/JAL_tokyo.jpeg`,
        amount: 0,
        name: "recommended-tokyo",
        search_params: {
          id: "fRZM",
          name: "wl.jal.destinations.tokyo",
          lat: 35.689506,
          lng: 139.6917,
        },
      }, {
        image_url: `${imageUrlPrefix}/jal/homepage/Recommended-Sapporo.jpg`,
        amount: 0,
        name: "recommended-sapporo",
        search_params: {
          id: "cLCF",
          name: "wl.jal.destinations.sapporo",
          lat: 43.0628680706,
          lng: 141.356964111,
        },
      }],
    };
  }

  private setupPopularDestinations() {
    this.popularDestinations = {
      overseas: [
        { id: "YrWt", carPickupId: "3605", nameKey: "wl.jal.destinations.honolulu", lat: 21.291982, lng: -157.821856 },
        { id: "YCcf", carPickupId: "179869", nameKey: "wl.jal.destinations.shanghai", lat: 31.222222, lng: 121.475 },
        { id: "DyKs", carPickupId: "182009", nameKey: "wl.jal.destinations.taipei", lat: 25.0846660766, lng: 121.561317444 },
        { id: "jC3Y", carPickupId: "246", nameKey: "wl.jal.destinations.london", lat: 51.5082616708, lng: -0.128059387207 },
        { id: "jiHz", carPickupId: "3802", nameKey: "wl.jal.destinations.new_york", lat: 40.757929, lng: -73.985506 },
        { id: "RsBU", carPickupId: "29544", nameKey: "wl.jal.destinations.singapore", lat: 1.2800945, lng: 103.8509491 },
        { id: "Zauv", carPickupId: "5081", nameKey: "wl.jal.destinations.bangkok", lat: 13.73078, lng: 100.521004 },
        { id: "vJh2", carPickupId: "1670", nameKey: "wl.jal.destinations.paris", lat: 48.856667, lng: 2.350987 },
        { id: "YYxz", carPickupId: "1714", nameKey: "wl.jal.destinations.melbourne", lat: -37.8141915082, lng: 144.962882996 },
        { id: "H4Gp", carPickupId: "169381", nameKey: "wl.jal.destinations.hong_kong", lat: 22.278333, lng: 114.158889 },
      ],
      domestic: [
        { id: "fRZM", carPickupId: "177093", nameKey: "wl.jal.destinations.tokyo", lat: 35.689506, lng: 139.6917 },
        { id: "cefD", carPickupId: "33396", nameKey: "wl.jal.destinations.aomori", lat: 40.83, lng: 140.74 },
        { id: "cLCF", carPickupId: "176197", nameKey: "wl.jal.destinations.sapporo", lat: 43.0628680706, lng: 141.356964111 },
        { id: "UFJv", carPickupId: "192433", nameKey: "wl.jal.destinations.kanagawa", lat: 35.183333, lng: 139.033333 },
        { id: "cdNS", carPickupId: "177577", nameKey: "wl.jal.destinations.kyoto", lat: 35.0080652565, lng: 135.745697021 },
        { id: "1y34", carPickupId: "33416", nameKey: "wl.jal.destinations.fukuoka", lat: 33.5684320854, lng: 130.358362198 },
        { id: "oWA8", carPickupId: "33564", nameKey: "wl.jal.destinations.osaka", lat: 34.6806523848, lng: 135.512237549 },
        { id: "URK1", carPickupId: "47476", nameKey: "wl.jal.destinations.kyushu", lat: 33.93, lng: 130.83 },
        { id: "9DFb", carPickupId: "152325", nameKey: "wl.jal.destinations.okinawa", lat: 26.2207510728, lng: 127.685852051 },
        { id: "qkqu", carPickupId: "177353", nameKey: "wl.jal.destinations.tochigi", lat: 36.75, lng: 139.54 },
      ],
    };
  }
}

// We're not adding the prefix Service here because we intended this to be a constant.
// The reason we're using service here is because constants cannot have dependencies.
angular.module("BookingApp").service("DestinationsList", DestinationsList);
