import { CityTile } from "booking_app/models";
import { DestinationsList } from "../../services";

export class LandingPageRecommendedDestinationsController {
  static $inject = [
    "$translate",
    "DestinationsList",
  ];

  // bindings
  onHotelSearch: (obj: {
    id: string,
    name: string,
    nights: number,
    lat: number,
    lng: number,
  }) => void;

  // local state
  popularTile: CityTile;
  normalTiles: CityTile[];

  constructor(
    private $translate: any,
    private destinationsList: DestinationsList,
  ) {
    this.setupTiles();
  }

  backgroundImgStyle(tile): { [key: string]: string } {
    return {
      "background-image": `url("${tile.image_url}")`,
    };
  }

  search(tile: CityTile): void {
    // use existing implementation on QuickSearchCtrl
    this.onHotelSearch({
      id: tile.search_params.id,
      name: this.$translate.instant(tile.search_params.name),
      nights: 3, // follows default values provided in QuickSearchCtrl
      lat: tile.search_params.lat,
      lng: tile.search_params.lng,
    });
  }

  private setupTiles(): void {
    this.popularTile = this.destinationsList.recommendedDestinations.popularTile;
    this.normalTiles = this.destinationsList.recommendedDestinations.normalTiles;
  }
}
