type productType = "redeem" | "earn";

export class JalLpProductTypeSwitchController {

  static $inject = ["$rootScope", "AppSettings", "$timeout"];

  constructor(
    public $rootScope: any,
    public AppSettings: any,
    private $timeout: any,
  ) { }

  isRedeem(): boolean {
    return this.productType() === "redeem";
  }

  isEarn(): boolean {
    return this.productType() === "earn";
  }

  onSwitchProductType(): void {
    // another special case of custom landing page switcher that doesnt reload the page
    this.$rootScope.quickSearchLoading = true;
    this.$rootScope.landingPage.url = this.AppSettings.landingPageUrl[this.productType()];
    this.$timeout(() => {
      this.$rootScope.quickSearchLoading = false;
    }, 10);
  }

  private productType(): productType {
    return this.$rootScope.globalState.productType;
  }
}
