import { Bindings } from "booking_app/types";
import { CheckoutFamilyMilesController } from "./checkout-family-miles.controller";

export class CheckoutFamilyMilesComponent {

  bindings: any = {
    personalMiles: Bindings.ONE_WAY,
    totalCharge: Bindings.ONE_WAY,
    useFamilyMiles: Bindings.ONE_WAY,
    familyMilesSelect: Bindings.EXPRESSION,
  };

  controller: any = CheckoutFamilyMilesController;
  templateUrl: string = "/html/components/checkout_family_miles";
}

angular.module("BookingApp").component("checkoutFamilyMiles", new CheckoutFamilyMilesComponent());
