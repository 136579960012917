import { JalLpProductTypeSwitchController } from "./lp-product-type-switch.controller";

// TODO: Translations
// We name this lp-product-type-switch because it is used only in landing page.
// Mainly to avoid name conflict with the product type switch used in pages beyond landing page.
export class JalLPProductTypeSwitchComponent {
  controller: any = JalLpProductTypeSwitchController;
  template: string = `
    <div class="switch-container">
      <span ng-class="{ 'form-input-radio': true, 'selected': $ctrl.isEarn() }">
        <input
          id="search-type-earn"
          type="radio"
          name="uSearchType"
          ng-model="$ctrl.$rootScope.globalState.productType"
          ng-change="$ctrl.onSwitchProductType('earn')"
          value="earn"
        >
        <label class="input-label" for="search-type-earn" translate="wl.jal.earn_miles"></label>
      </span>
      <span ng-class="{ 'form-input-radio': true, 'selected': $ctrl.isRedeem() }">
      <input
        id="search-type-redeem"
        type="radio"
        name="uSearchType"
        ng-model="$ctrl.$rootScope.globalState.productType"
        ng-change="$ctrl.onSwitchProductType('redeem')"
        value="redeem"
      >
      <label class="input-label" for="search-type-redeem" translate="txt.redeem_miles"></label>
    </span>
    </div>
  `;
}

angular.module("BookingApp").component("jalLpProductTypeSwitch", new JalLPProductTypeSwitchComponent());
