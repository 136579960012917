export class JalHeaderController {
  static $inject = [
    "$rootScope",
    "$location",
  ];

  constructor(
    private $rootScope: any,
    private $location: any,
  ) {
  }

  goToHomePage(): void {
    this.$location.url("/");
  }
}
