import { UserAgentChecker } from "booking_app/utils/user-agent-checker";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { CurrentPage } from "booking_app/types";

export class JalNavigationBarController {
  static $inject = ["$rootScope", "$location", "KaligoConfig",
    "UserAgentChecker", "GlobalStateService"];

  constructor(
    private $rootScope: any,
    private $location: any,
    public KaligoConfig: any,
    public userAgentChecker: any,
    private globalStateService: GlobalStateService,
  ) {
  }

  public hideNavBar(): boolean {
    const restrictedPages = [CurrentPage.CHECKOUT, CurrentPage.CONFIRMATION];
    return restrictedPages.includes(this.globalStateService.currentPage);
  }

  public displayHover(): boolean {
    return !this.userAgentChecker.isMobileOrTablet();
  }
}
