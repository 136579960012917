import { LocationType, TravelType } from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";

export class PopularDestinationsController {
  static $inject = [
    "$rootScope",
    "GlobalStateService",
  ];

  // bindings
  locationType: LocationType;
  onHotelSearch: (obj: {
    id: string,
    name: string,
    lat: number,
    lng: number,
  }) => void;
  teaserKey: string;

  constructor(
    private $rootScope: any,
    private globalStateService: GlobalStateService,
  ) {}

  public onSearch(id, name, lat, lng): void {
    this.onHotelSearch({ id, name, lat, lng });
  }

  public hidePopularDestinations(): boolean {
    return (this.locationType === "overseas") && (this.globalStateService.isTravelType(TravelType.CARS));
  }
}
