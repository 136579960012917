import { Bindings } from "booking_app/types";
import { JalHeaderController } from "./jal-header.controller";

export class JalHeaderComponent {
  bindings: any = {
    navBarText: Bindings.ONE_WAY,
    navAction: Bindings.EXPRESSION,
  };
  controller: any = JalHeaderController;
  template: string = `
    <header>
      <div class="max-size">
        <div class="logo-container" ng-click="$ctrl.goToHomePage()" role="link">
          <img cdn-path="/jal/homepage/JMBWM-logo.png">
        </div>
        <div class="header-menu-container">
          <jal-header-navigation></jal-header-navigation>
        </div>
      </div>
    </header>
  `;
}

angular.module("BookingApp").component("jalHeader", new JalHeaderComponent());
