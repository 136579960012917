import { Bindings } from "booking_app/types";
import { JalProductTypeSwitchController } from "./jal-product-type-switch.controller";

export class JalProductTypeSwitch {
  controller: any = JalProductTypeSwitchController;
  bindings: any = {
    isMobile: Bindings.ONE_WAY,
  };
  template: string = `
    <button class="toggle-product-container" ng-click="$ctrl.triggerSearch()" tabindex="0">
      <div class="toggle-text">
        <span class="product-text" ng-bind-html="$ctrl.productText()"></span>
      </div>
    </button>
  `;
}

angular.module("BookingApp").component("jalProductTypeSwitch", new JalProductTypeSwitch());
