import { PopularDestinationsListController } from "./popular-destinations-list.controller";
import { Bindings } from "booking_app/types";

export class PopularDestinationsList {
  bindings: any = {
    locationType: Bindings.ONE_WAY,
    onSearch: Bindings.EXPRESSION,
  };
  controller: any = PopularDestinationsListController;
  template: string = `
    <div class="popular-destinations-list">
      <ul class="destinations-list">
        <li class="destination" ng-repeat="destination in $ctrl.destinationsData()">
          <a ng-click="$ctrl.searchForDestination(destination)" translate="{{destination.nameKey}}"></a>
        </li>
      </ul>
    </div>
  `;
}

angular.module("BookingApp").component("popularDestinationsList", new PopularDestinationsList());
