export class CheckoutFamilyMilesController {

  // bindings
  personalMiles: number = 0;
  totalCharge: number = 0;
  useFamilyMiles: boolean;
  familyMilesSelect: (obj: { selected: boolean }) => void;

  milesTypeChanged(familySelected) {
    this.familyMilesSelect({selected: familySelected});
  }

  $onChanges(changes): void {
    this.milesTypeChanged(this.familyMilesSelected());
  }

  familyMilesSelected(): boolean {
    return (this.useFamilyMiles === undefined) ? true : this.useFamilyMiles;
  }

}
