import { PopularDestinationsController } from "./popular-destinations.controller";
import { Bindings } from "booking_app/types";

export class PopularDestinations {
  bindings: any = {
    locationType: Bindings.ONE_WAY,
    onHotelSearch: Bindings.EXPRESSION,
    teaserKey: Bindings.ONE_WAY,
  };
  controller: any = PopularDestinationsController;
  template: string = `
    <div class="popular-destinations-background" ng-hide="$ctrl.hidePopularDestinations()">
      <div class="popular-destinations-container max-size">
        <div class="popular-destinations-teaser">
          <h1 class="popular-destinations-quick-links"
              translate="{{$ctrl.teaserKey}}">
          </h1>
        </div>
        <popular-destinations-list
          location-type="$ctrl.locationType",
          on-search="$ctrl.onSearch(id, name, lat, lng)">
        </popular-destinations-list>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("popularDestinations", new PopularDestinations());
