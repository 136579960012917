import { CarsSearchService } from "booking_app/services/cars/cars-search.service";
import { DestinationsList } from "../../../services/destinations-list";
import {
  LocationType,
  PopularDestination,
  TravelType,
} from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";

export class PopularDestinationsListController {
  static $inject = [
    "$rootScope",
    "$translate",
    "$window",
    "CarsSearchService",
    "DestinationsList",
    "GlobalStateService",
  ];

  // bindings
  locationType: LocationType;
  onSearch: (obj: {
    id: string,
    name: string,
    lat: number,
    lng: number,
  }) => void;

  // localState
  localData: PopularDestination[];

  constructor(
    private $rootScope: any,
    private $translate: any,
    private $window: any,
    private carsSearchService: CarsSearchService,
    private destinationsList: DestinationsList,
    private globalStateService: GlobalStateService,
  ) {}

  $onInit() {
    this.setDestinationsData();
  }

  public searchForDestination(destination: PopularDestination): void {
    if (this.globalStateService.isTravelType(TravelType.HOTELS)) {
      const translatedName = this.$translate.instant(destination.nameKey);

      this.onSearch({ id: destination.id, name: translatedName, lat: destination.lat, lng: destination.lng });
    } else {
      this.carsSearchService.searchCars({
        pickupLocationId: destination.carPickupId,
        returnLocationId: destination.carPickupId,
      });
    }
  }

  public setDestinationsData() {
    if (this.locationType === "overseas") {
      this.localData = this.destinationsList.popularDestinations.overseas;
    } else {
      this.localData = this.destinationsList.popularDestinations.domestic;
    }
  }

  public destinationsData(): PopularDestination[] {
    return this.localData;
  }
}
